import 'regenerator-runtime';
import '../styles/main.css';
import './views/components/index';
import { getCurrentRoute } from './routes/routes';
import swRegister from './utils/sw-register';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const handleLocation = async () => {
  document.querySelector('body').scrollIntoView();
  const { page, title, params } = getCurrentRoute();
  const pageData = { title, params };
  document.getElementById('content').innerHTML = await page.render(pageData);
  document.title = `FeastFinder - ${title}`;
  await page.afterRender(pageData);
};

window.addEventListener('hashchange', handleLocation);
window.addEventListener('DOMContentLoaded', async () => {
  await handleLocation();

  const skipLink = document.querySelector('.skip-to-content');
  const content = document.querySelector('#content');
  skipLink.addEventListener('click', (e) => {
    e.preventDefault();
    content.scrollIntoView();
    content.focus();
  });
  skipLink.blur();

  await swRegister();
});
