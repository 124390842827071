import CONFIG from '../globals/config';

class RestaurantApi {
  constructor() {
    this.BASE_URL = CONFIG.API_BASE_URL;
    this.API_KEY = CONFIG.API_KEY;
    this.BASE_IMAGE_URL = CONFIG.BASE_IMAGE_URL;
  }

  async list() {
    const response = await fetch(`${this.BASE_URL}/list`);
    const data = await response.json();
    return data.restaurants;
  }

  async detail(id) {
    const response = await fetch(`${this.BASE_URL}/detail/${id}`);
    const data = await response.json();
    return data.restaurant;
  }

  async search(query) {
    const response = await fetch(`${this.BASE_URL}/search?q=${query}`);
    const data = await response.json();
    return data.restaurants;
  }

  async addReview(id, name, review) {
    const response = await fetch(`${this.BASE_URL}/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, name, review }),
    });
    const data = await response.json();
    return data.customerReviews;
  }

  getImageUrl(imageId, quality) {
    quality = quality || 'medium';
    return `${this.BASE_IMAGE_URL}/${quality}/${imageId}`;
  }
}

export default new RestaurantApi();
